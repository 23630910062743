<template>
<div class="page-container">
  <section-title>
    <img class="note-icon" src="@/assets/images/register/icon_ts.png" alt="">
    注意事项</section-title>
  <notice :content="notice | decodeHTML"></notice>
  <section-title show-asterisk>补交照片</section-title>
  <notice :content="picNotice | decodeHTML" style="margin-bottom: 10px"></notice>
  <pic-uploader :only-camera="false" @change="url=>form.tem_img=url" :src="form.tem_img" :example="require('@/assets/images/examplePic/example14.png')"></pic-uploader>
  <section-title show-asterisk>信息填写</section-title>
  <cell-input title="机主真实姓名" placeholder="请输入机主真实姓名" v-model="form.name"></cell-input>
  <cell-input title="机主手机号" placeholder="请输入机主手机号" v-model="form.phone" type="tel"></cell-input>
  <scan-code title="手机IMEI号" placeholder="请输入或扫描手机IMEI号" v-model="form.imei" @myInput="imitInput"></scan-code>
  <field-piker label="补交照片类型" title="请选择补交照片类型" placeholder="请选择补交照片类型" v-model="form.relation_type"
               :columns="typeList" style="margin-bottom: 20px" value-key="name"></field-piker>
  <my-button class="submit-btn" :type="isDone?'primary':'default'"
             @click="submit">提交
  </my-button>
</div>
</template>

<script>
import {isFilled} from "@/utils";
import {getRelationType, setOrderMake} from "@/api";

export default {
  name: "index",
  data() {
    return{
      notice:'在您注册失败被拒单时，可在此界面补交正确的照片。',
      picNotice:'<p>注：请按照下图所示上传主机碎屏手机正面照片。</p><p style="color: #E86161">（照片选择错误时请点击图片重新选择）</p>',
      form:{
        tem_img:'',
        name:'',
        phone: '',
        imei: '',
        relation_type:''
      },
      typeList:[],
      isDone:false
    }
  },
  watch:{
    form:{
      handler(form) {
        this.checkForm(form)
      },deep:true
    }
  },
  mounted() {
    this.getTypeList()
  },
  methods:{
    // 解决串号组件苹果手机无法识别@change问题
    imitInput(e){
      this.form.imei = e.target.value; 
    },
    getTypeList() {
      getRelationType().then(res=>{
        this.typeList=res.data||[]
      })
    },
    checkForm(form,showToast=false) {
      // console.clear()
      let isDone = true
      for (const key in form) {
        if (!isFilled(form[key])) {
          isDone = false;
          if (showToast) {
            switch (key) {
              case 'tem_img':
                this.$toast('请上传补交照片')
                break
              case 'name':
                this.$toast('请输入机主真实姓名')
                break
              case 'phone':
                this.$toast('请输入机主手机号')
                break
              case 'imei':
                this.$toast('请输入或扫描手机IMEI号')
                break
              case 'relation_type':
                this.$toast('请选择补交照片类型')
                break
            }
            return
          }
        }
      }
      this.isDone = isDone
      return isDone
    },
    submit() {
      if (this.checkForm(this.form, true)) {
        let userInfo=localStorage.getItem('userInfo')
        if (!userInfo) {
          this.$toast('获取用户信息失败')
          return
        }
        userInfo=JSON.parse(userInfo);
        console.log(`%c u`,'color:red;font-size:20px',userInfo)
        setOrderMake({
          openid:userInfo.openid,
          nickname:userInfo.nickname,
          headimg:userInfo.headimg,
          tem_img:this.form.tem_img,
          name:this.form.name,
          phone:this.form.phone,
          imei:this.form.imei,
          relation_type:this.form.relation_type.id,
        }).then(()=>{
          this.$myDialog({
            type: 'success',
            title: '提交成功',
            confirmButtonText: '知道了',
            beforeClose:(action, done)=>{
              done()
            }
          });
        }).catch(err=>{
          console.error(err&&err.msg||'提交失败')
          this.$toast(err && err.msg || '提交失败');
        })
      }
    }
  }
}
</script>

<style scoped>
.note-icon {
  width: 28px;
  height: 28px;
  margin-right: 10px;
}

</style>
