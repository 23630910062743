//判断form的子项是否已填
//值为空，空对象，空数组
export function isFilled(value) {
    if (value === null || value === undefined || value === '') {
        return false
    } else if (typeof value === 'object' && JSON.stringify(value) === '{}') {
        return false
    } else if (Array.isArray(value) && value.length === 0) {
        return false
    }
    return true
}
